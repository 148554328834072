<template>
    <div class="wrap">
        <div style="padding: 20px;">
            <h1>Dashboard</h1>
        </div>
    </div>
</template>

<script setup>
// import { getSellerReport } from '~/server/api';
import { publisherAdminApi } from '~/server/index'
const init = () => {
    publisherAdminApi.getSellerReport().then(res => {
    // todo
    })
}

init();
</script>

<style lang="less">
.wrap {
    display: flex;
    width: 100dvw;
    height: 100dvh;
    overflow-x: scroll;
    overflow-y: hidden;
}
</style>